<template>
  <a-form :ref="formRef" scrollToFirstError>
    <div class="accounts-meeting-modal-main-wrapper">
      <div class="account-meeting-text">Meeting</div>

      <div class="clien-person-date-wrapper">
        <div class="client-person-wrapper">
          <div class="client-person-text">
            Client Person<span style="color: red">*</span>
          </div>
          <div>
            <a-form-item v-bind="validateInfos.clientPersons">
              <a-select
                v-model:value="modelRef.clientPersons"
                mode="tags"
                style="width: 100%"
                placeholder="Select"
                :options="clientPersonOptions"
                @change="handleChange"
                class="export-resume-select-tags"
              >
              </a-select>
            </a-form-item>
          </div>
        </div>
        <div class="date-wrapper">
          <div class="client-person-text">Date</div>
          <div>
            <a-form-item v-bind="validateInfos.date">
              <a-date-picker
                v-model:value="modelRef.date"
                class="meeting-date-picker"
                placeholder="Select"
              />
            </a-form-item>
          </div>
        </div>
      </div>

      <div class="department-team-wrapper">
        <div class="client-person-wrapper">
          <div class="client-person-text">Department</div>
          <div>
            <a-form-item v-bind="validateInfos.departmentId">
              <a-select
                v-model:value="modelRef.departmentId"
                style="width: 100%"
                placeholder="Select"
                :options="departmentsOptions"
                @change="handleChange"
                class="export-resume-select-tags"
              >
              </a-select>
            </a-form-item>
          </div>
        </div>
        <div class="date-wrapper">
          <div class="client-person-text">
            Team<span style="color: red">*</span>
          </div>
          <div>
            <a-form-item v-bind="validateInfos.teamIds">
              <a-select
                v-model:value="modelRef.teamIds"
                style="width: 100%"
                placeholder="Select"
                :options="teamsOptions"
                class="export-resume-select-tags"
                @change="getSubteam(modelRef.teamIds)"
              >
              </a-select>
            </a-form-item>
          </div>
        </div>
      </div>

      <div class="sub-team-wrapper">
        <div class="client-person-wrapper">
          <div class="client-person-text">
            Sub Team<span style="color: red">*</span>
          </div>
          <div>
            <a-form-item v-bind="validateInfos.subTeamIds">
              <a-select
                v-model:value="modelRef.subTeamIds"
                style="width: 100%"
                placeholder="Select"
                :options="sub_teamsOptions"
                @change="handleChange"
                class="export-resume-select-tags"
              >
              </a-select>
            </a-form-item>
          </div>
        </div>
      </div>

      <div class="department-team-wrapper">
        <div class="client-person-wrapper">
          <div class="client-person-text">Meeting Type</div>
          <div>
            <a-form-item v-bind="validateInfos.type">
              <a-select
                v-model:value="modelRef.type"
                style="width: 100%"
                placeholder="Select"
                :options="meetingOptions"
                @change="handleChange"
                class="export-resume-select-tags"
              >
              </a-select>
            </a-form-item>
          </div>
        </div>
        <div class="date-wrapper">
          <div class="client-person-text">
            Lead CIC<span style="color: red">*</span>
          </div>
          <div>
            <a-form-item v-bind="validateInfos.leadCics">
              <a-select
                v-model:value="modelRef.leadCics"
                style="width: 100%"
                placeholder="Select"
                :options="userOptions"
                @change="handleChange"
                class="export-resume-select-tags"
              >
              </a-select>
            </a-form-item>
          </div>
        </div>
      </div>

      <div class="sub-team-wrapper">
        <div class="client-person-wrapper">
          <div class="client-person-text">Attended CIC</div>
          <div>
            <a-select
              v-model:value="value"
              style="width: 100%"
              placeholder="Select"
              :options="userOptions"
              @change="handleChange"
              class="export-resume-select-tags"
            >
            </a-select>
          </div>
        </div>
      </div>

      <div class="avatar-with-remark-wrapper">
        <div style="margin-right: 20px">
          <a-avatar>
            <template #icon>
              <UserOutlined />
            </template>
          </a-avatar>
        </div>
        <div style="width: 100%; margin-bottom: 10px">
          <a-form-item v-bind="validateInfos.remarks">
            <a-textarea
              v-model:value="modelRef.remarks"
              placeholder="Remark*"
              allow-clear
              :rows="4"
              class="meeting-modal-remark"
            />
          </a-form-item>
        </div>
      </div>
      <div class="meeting-footer-modal">
        <div class="link-button-meeting">
          <a-button @click="clearMeeting()" type="link">Clear</a-button>
        </div>
        <div>
          <a-button @click="onSubmit()" type="primary">Save</a-button>
        </div>
      </div>
    </div>
  </a-form>
</template>


<script>
import { UserOutlined } from "@ant-design/icons-vue";
import Services from "@/services/apis";
import { ref, onMounted, reactive } from "vue";
import { Form, message, Modal } from "ant-design-vue";
import { useRoute } from "vue-router";
const useForm = Form.useForm;
export default {
  components: { UserOutlined },
  setup(props,context) {
    let clientPersonOptions = ref([]);
    let departmentsOptions = ref([]);
    let userOptions = ref([]);
    let teamsOptions = ref([]);
    let sub_teamsOptions = ref([]);
    let clientPersonData = ref([]);
    let departmentData = ref([]);
    let teamsData = ref([]);
    let sub_teamsData = ref([]);
    let meetingData = ref([]);
    let meetingOptions = ref([]);
    const formRef = ref();
    const modelRef = reactive({
      clientPersons: ref([]),
      date: ref(),
      teamIds: ref(''),
      subTeamIds: ref([]),
      type: ref([]),
      remarks: ref([]),
      departmentId: ref([]),
      leadCics: ref([]),
      attendees: ref([]),
      category: "ACCOUNT",
    });
    const rulesRef = reactive({
      clientPersons: [
        {
          required: true,
          message: "Please select Client Person",
        },
      ],
      teamIds: [
        {
          required: true,
          message: "Please select Meeting team",
        },
      ],
      subTeamIds: [
        {
          required: true,
          message: "Please select Meeting team",
        },
      ],
      
      leadCics: [
        {
          required: true,
          message: "Please select Lead Cics",
        },
      ],
      remarks: [
        {
          required: true,
          message: "Please Enter Remark",
        },
      ]
    });

    const { resetFields, validate, validateInfos } = useForm(
      modelRef,
      rulesRef
    );
    let payload = {
      q: "",
      pageNo: 0,
      mode: "LISTING",
    };
    const clientListing = () => {
      Services.getClientPersonListing(payload)
        .then((response) => {
          const { data } = response;

          let tempData = [];
          data[0].client_persons.map((x) => {
            tempData.push({
              value: x.user.id,
              label: x.user.name,
            });
          });
          Object.assign(clientPersonOptions.value, tempData);
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    };
    const getSubteam = (id) => {
      Services.teamListing({ teams: [id], types: ["SUB_TEAM"] })
        .then((response) => {
          const { data } = response;

          let tempTeams = [];
          data.data.map((x) => {
            tempTeams.push({
              value: x.id,
              label: x.name,
            });
          });
          Object.assign(sub_teamsOptions.value, tempTeams);
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    };
    const getTeam = () => {
      Services.teamListing({ types: ["TEAM"] })
        .then((response) => {
          const { data } = response;

          let tempTeams = [];
          data.data.map((x) => {
            tempTeams.push({
              value: x.id,
              label: x.name,
            });
          });
          Object.assign(teamsOptions.value, tempTeams);
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    };
    const getDepartment = () => {
      Services.getDepartmentsDetail()
        .then((response) => {
          const { data } = response;

          let tempdepartment = [];
          data[0].data.map((x) => {
            tempdepartment.push({
              value: x.id,
              label: x.name,
            });
          });
          Object.assign(departmentsOptions.value, tempdepartment);
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    };
    const getMaster = () => {
      Services.genderListing({ types: "MEETING_TYPES" })
        .then((response) => {
          const { data } = response;

          let tempTeams = [];
          data[0].data.map((x) => {
            tempTeams.push({
              value: x.id,
              label: x.name,
            });
          });
          Object.assign(meetingOptions.value, tempTeams);
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    };
    const getUser = () => {
      Services.userListing({pageNo:0})
        .then((response) => {
          const { data } = response;

          let tempUsers = [];
          data.data.map((x) => {
            tempUsers.push({
              value: x.id,
              label: x.name,
            });
          });
          Object.assign(userOptions.value, tempUsers);
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    };
    const closeMeeting = () => {
      context.emit("closeMeeting");
    }
    const clearMeeting = () => {
      modelRef.clientPersons= ref([]),
      modelRef.date= ref(),
      modelRef.teamIds= ref(''),
      modelRef.subTeamIds=ref([]),
      modelRef.type=ref([]),
      modelRef.remarks= ref([]),
      modelRef.departmentId= ref([]),
      modelRef.leadCics= ref([]),
      modelRef.attendees= ref([]),
      modelRef.category= "ACCOUNT"
    }
    const onSubmit = () => {
      console.log('object :>> ');
      validate()
        .then((result) => {
          modelRef.teamIds = [modelRef.teamIds];
          modelRef.leadCics = [modelRef.leadCics];
          modelRef.subTeamIds = [modelRef.subTeamIds];
          modelRef.subTeamIds.length < 1 ? modelRef.subTeamIds = modelRef.teamIds : modelRef.subTeamIds  
          Services.getMeetings(modelRef).then((response) => {
            const { data } = response;

            message.success("Meeting created successfully");
            // location.reload();
          });
          context.emit("closeMeeting");
        })
        .catch((err) => {});
    };
    onMounted(() => {
      clientListing();
      getSubteam();
      getTeam();
      getDepartment();
      getMaster();
      getUser();
    });

    return {
      validate,
      validateInfos,
      resetFields,
      formRef,
      getSubteam,
      clientPersonOptions,
      departmentsOptions,
      clearMeeting,
      meetingOptions,
      clientPersonData,
      userOptions,
      departmentData,
      teamsData,
      sub_teamsData,
      meetingData,
      onMounted,
      teamsOptions,
      sub_teamsOptions,
      clientListing,
      modelRef,
      onSubmit,
      closeMeeting,
    };
  },
};
</script>
<style lang="scss">
@import "../styles/MeetingModal.scss";
</style>